import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import i18n from "./i18n/i18n";

function withRoot(Component) {
  function WithRoot(props) {
    // JssProvider allows customizing the JSS styling solution.
    return (
      <ThemeProvider
        theme={createTheme({
          direction: i18n.dir(),
          palette: {
            primary: {
              main: "#5475fb",
            },
            secondary: {
              main: "#2a4f96",
            },
            ternary: {
              main: "#233863",
            },
          },
          typography: {
            fontFamily: "Roboto, Noto Sans Arabic",
          },
        })}
      >
        <Component {...props} />
      </ThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
