import Routing from "./routes";
import { useTranslation } from "react-i18next";
import withRoot from "./withRoot";
import { useTheme } from "@mui/material";
import "./App.css";

function App() {
  const { i18n } = useTranslation();
  const theme = useTheme();
  document.body.dir = i18n.dir();
  const language = i18n.language;
  // theme.palette.primary.main = "#5475fb";

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
  };

  return (
    <div className="App">
      <Routing language={language} changeLanguage={changeLanguage} />
    </div>
  );
}

export default withRoot(App);
