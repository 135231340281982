import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import logo from "../../images/logo.jpg";
import { useNavigate } from "react-router-dom";
import { Select } from "@mui/material";
import { useTranslation } from "react-i18next";

const ResAppBar = ({ language, changeLanguage }) => {
  const { t } = useTranslation();

  const pages = [
    {
      id: 1,
      title: t("about_us"),
      path: "/about",
    },
    {
      id: 2,
      title: t("services"),
      path: "/services",
    },
    {
      id: 3,
      title: t("contact_us"),
      path: "/contact",
    },
  ];
  const navigateTo = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleChange = (event) => {
    changeLanguage(event.target.value);
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      sx={{ height: "80px", justifyContent: "center" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              navigateTo("/");
            }}
          >
            <img src={logo} width={60} height={60} alt={"company logo"} />
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.id}
                  onClick={() => {
                    handleCloseNavMenu();
                    navigateTo(page.path);
                  }}
                >
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
            }}
            onClick={() => {
              navigateTo("/");
            }}
          >
            <img src={logo} width={50} height={50} alt={"company logo"} />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              gap: 2,
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.id}
                onClick={() => {
                  navigateTo(page.path);
                  handleCloseNavMenu();
                }}
                sx={{
                  my: 2,
                  display: "block",
                  color: "primary.main",
                }}
              >
                {page.title}
              </Button>
            ))}
          </Box>
          <Box>
            <Select
              size="small"
              variant="standard"
              value={language}
              displayEmpty
              onChange={handleChange}
            >
              <MenuItem value={"en"}>English</MenuItem>
              <MenuItem value={"ar"}>Arabic</MenuItem>
            </Select>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResAppBar;
