import React, { lazy, Suspense } from "react";
import { LazyMotion, domAnimation, AnimatePresence } from "framer-motion";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loader from "../components/Loader";
import ResAppBar from "../components/ResAppBar";
import ScrollToTop from "./ScrollToTop";

// Accounting
const HomePage = lazy(() => import("../pages/HomePage"));
const AboutUs = lazy(() => import("../pages/AboutUs"));
const Services = lazy(() => import("../pages/Services"));
const ContactUs = lazy(() => import("../pages/ContactUs"));

// Misc
const PageNotFound = lazy(() => import("../components/NoPageFound"));

const Routing = ({ language, changeLanguage }) => {
  return (
    <div>
      <Suspense fallback={<Loader minHeight={"80vh"} />}>
        <Router>
          <AnimatePresence>
            <LazyMotion features={domAnimation}>
              <ScrollToTop />
              <ResAppBar language={language} changeLanguage={changeLanguage} />
              <Routes>
                {/* Accounting - Accountant & AH */}
                <Route path="/" element={<HomePage />}></Route>
                <Route path="/about" element={<AboutUs />}></Route>
                <Route path="/services" element={<Services />}></Route>
                <Route path="/contact" element={<ContactUs />}></Route>
                {/* Misc */}
                <Route path="*" element={<PageNotFound />}></Route>
              </Routes>
            </LazyMotion>
          </AnimatePresence>
        </Router>
      </Suspense>
    </div>
  );
};

export default Routing;
